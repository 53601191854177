/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'european-stars': {
    width: 300,
    height: 300,
    viewBox: '0 0 300 300',
    data: '<g id="svgicon_european-stars_c" transform="translate(150 30)" _fill="#fc0"><g id="svgicon_european-stars_b"><path pid="0" id="svgicon_european-stars_a" d="M0-20V0h10" transform="rotate(18 0 -20)"/><use xlink:href="#svgicon_european-stars_a" transform="scale(-1 1)"/></g><use xlink:href="#svgicon_european-stars_b" transform="rotate(72)"/><use xlink:href="#svgicon_european-stars_b" transform="rotate(144)"/><use xlink:href="#svgicon_european-stars_b" transform="rotate(216)"/><use xlink:href="#svgicon_european-stars_b" transform="rotate(288)"/></g><use xlink:href="#svgicon_european-stars_c" transform="translate(60 16.077)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(103.923 60)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(120 120)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(103.923 180)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(60 223.923)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(0 240)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(-60 223.923)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(-103.923 180)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(-120 120)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(-103.923 60)"/><use xlink:href="#svgicon_european-stars_c" transform="translate(-60 16.077)"/>'
  }
})
